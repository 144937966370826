import { Input, styled } from "@mui/material";

// TODO review border 3px when isError
export const StyledInput = styled(Input)(({ isError, theme }) => ({
  "backgroundColor": theme.palette.grey[100],
  "border": `1px solid ${
    isError ? theme.palette.error.main : theme.palette.grey[500]
  }`,
  "borderRadius": isError ? "4px 4px 0px 0px" : "4px",
  "padding": "8px",
  "&:hover": {
    border: `1px solid ${
      isError ? theme.palette.error.main : theme.palette.grey[900]
    }`,
  },
  "&.Mui-focused": {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${
      isError ? theme.palette.error.main : theme.palette.grey[900]
    }`,
    ...theme.typography.body_bold,
  },
  "&.Mui-disabled": {
    border: "none",
    color: theme.palette.grey[200],
  },
  "& .MuiInput-input": {
    "padding": "0px",
    "backgroundColor": "inherit",
    "&.Mui-disabled": {
      WebkitTextFillColor: theme.palette.grey[200],
    },
    // Review body_bold, generate displacement
    "&:focus": {
      ...theme.typography.body_bold,
    },
    ...theme.typography.body,
  },
}));
