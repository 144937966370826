import { Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "components/Button";
import { FooterIcon } from "components/icons/JoinIcon";
import { Link } from "components/Link";
import { getSignupUrl } from "lib/helpers/urls";
import { useTranslation } from "react-i18next";

export const Member = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        p: isMobile ? 6 : 7,
        flexDirection: isMobile ? "column" : "row",
        mx: isMobile ? "0px" : "24px",
        alignItems: isMobile ? "center" : "flex-start",
        gap: 7,
        mt: isMobile ? 11 : 10,
        borderRadius: 6,
        background: theme.palette.sand.main,
        borderBottom: `6px solid ${theme.palette.grey[900]}`,
        maxWidth: isMobile ? "100%" : "1200px",
      })}
    >
      <Box
        sx={{
          position: "relative",
          bottom: isMobile ? "60px" : "0px",
        }}
      >
        <FooterIcon />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: isMobile ? "-60px" : "0px",
        }}
      >
        <Typography variant={isMobile ? "h3" : "h2"}>
          {t("funnel.product_picker.only_member.title")}
        </Typography>
        <Typography variant={isMobile ? "body" : "body_large"}>
          {t("funnel.product_picker.only_member.subtitle")}
        </Typography>
        <Link
          target="_parent"
          to={getSignupUrl([], "member")}
          showUnderline={false}
          style={{ maxWidth: "150px" }}
        >
          <Button sx={{ mt: 4 }} fullWidth>
            {t("funnel.product_picker.only_member.button")}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
