import axios from "axios";

export const createTicket = async ({
  body,
  subject,
  meta,
  override_ticket_ids,
}) => {
  return await axios.post(`/tickets/`, {
    body,
    subject,
    meta,
    override_ticket_ids,
  });
};

const createPublicTicket = async ({ body, subject, meta }) => {
  return await axios.post(`/tickets/public/`, {
    body,
    subject,
    meta,
  });
};

export const getTickets = async ({ type, ...filters }) => {
  return await axios.get(`/tickets/`, {
    params: { ticket_type: type, ...filters },
  });
};

export const postRequestChange = async ({
  topic,
  newValue,
  scope,
  selectedSubscriptions,
  lang,
  documentation,
}) => {
  return await createTicket({
    body: "",
    subject: "-",
    meta: [
      {
        key: "ticket_type",
        value: `change_${topic}`,
      },
      {
        key: "new_value",
        value: newValue,
      },
      {
        key: "scope",
        value: scope,
      },
      {
        key: "selected_subscriptions",
        value: selectedSubscriptions,
      },
      {
        key: "language",
        value: lang,
      },
      {
        key: "docs",
        value: documentation,
      },
    ],
  });
};

export const postWeCallYou = async ({
  lang,
  name,
  schedule,
  phone,
  reason,
  company_size,
  is_company,
}) => {
  return await createPublicTicket({
    body: "",
    subject: "-",
    meta: [
      {
        key: "ticket_type",
        value: "we_call_you",
      },
      {
        key: "language",
        value: lang,
      },
      {
        key: "name",
        value: name,
      },
      {
        key: "schedule",
        value: schedule,
      },
      {
        key: "phone",
        value: phone,
      },
      {
        key: "reason",
        value: reason,
      },
      {
        key: "company_size",
        value: company_size,
      },
      {
        key: "is_company",
        value: is_company,
      },
    ],
  });
};

export const postCheckCoverageForm = async ({
  lang,
  name,
  surnames,
  email,
  phone,
  contact_lang,
  road_type,
  road_name,
  road_number,
  block,
  doorway,
  floor,
  scale,
  door,
  other_information,
  locality,
  state,
  zip_code,
}) => {
  return await createPublicTicket({
    body: "",
    subject: "-",
    meta: [
      {
        key: "ticket_type",
        value: "check_coverage",
      },
      { key: "language", value: lang },
      {
        key: "name",
        value: name,
      },
      {
        key: "surnames",
        value: surnames,
      },
      {
        key: "email",
        value: email,
      },
      {
        key: "phone",
        value: phone,
      },
      {
        key: "contact_lang",
        value: contact_lang,
      },
      {
        key: "road_type",
        value: road_type,
      },
      {
        key: "road_name",
        value: road_name,
      },
      {
        key: "road_number",
        value: road_number,
      },
      {
        key: "block",
        value: block,
      },
      {
        key: "doorway",
        value: doorway,
      },
      {
        key: "floor",
        value: floor,
      },
      {
        key: "scale",
        value: scale,
      },
      {
        key: "door",
        value: door,
      },
      {
        key: "other_information",
        value: other_information,
      },
      {
        key: "locality",
        value: locality,
      },
      {
        key: "state",
        value: state,
      },
      {
        key: "zip_code",
        value: zip_code,
      },
    ],
  });
};

export const postChangePackNotSharedData = async (
  contractsRef,
  withLandLine
) => {
  return await createTicket({
    body: "",
    subject: "-",
    meta: [
      {
        key: "ticket_type",
        value: "change_pack_not_shared_data",
      },
      {
        key: "selected_subscriptions",
        value: contractsRef,
      },
      {
        key: "fiber_without_landline",
        value: !withLandLine,
      },
    ],
  });
};
