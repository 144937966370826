import React, { useState, useEffect, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactDOM from "react-dom";
import {
  HashRouter,
  MemoryRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { QueryParamProvider } from "use-query-params";

import { Spinner } from "./components/Spinner";
import { IdleTracker } from "./components/IdleTracker";
import { initialize } from "initializers";
import { Tariffs } from "screens/Tariffs";
import { SignUp } from "screens/SignUp";
import Faqs from "screens/Faqs";
import Coverage from "screens/Coverage";
import { Join } from "screens/Join";
import { useQueryParams } from "hooks/useQueryParams";
import { parseUrl } from "lib/helpers/urls";

import { theme } from "./theme";
import "./App.css";
import { Companies } from "screens/Companies";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Routes = () => {
  const { locale = "ca" } = useQueryParams();
  return (
    <Switch>
      <Route path="/join">
        <Join />
      </Route>
      <Route path="/tariffs/:type">
        <Tariffs />
      </Route>
      <Route path="/product-picker">
        <Join />
      </Route>
      <Route path="/signup">
        <SignUp />
      </Route>
      <Route path="/faqs">
        <Faqs />
      </Route>
      <Route path="/coverage">
        <Coverage />
      </Route>
      <Route path="/distributor">
        <Join exclusiveTariffs />
      </Route>
      <Route path="/companies">
        <Companies />
      </Route>
      <Route
        path="*"
        render={() => (
          <Redirect to={{ pathname: "/join", search: `?locale=${locale}` }} />
        )}
      />
    </Switch>
  );
};

const SplashScreen = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      flex: 1,
      display: "flex",
      alignItems: "center",
    }}
  >
    <Spinner />
  </div>
);

function shouldRedirect(path = "") {
  if (!path) return path;

  const { hash } = window.location;
  const [tgtPath, tgtQuery = ""] = path.split("?");
  const [srcPath, srcQuery = ""] = hash.slice(1).split("?");

  if (!(srcPath && srcPath.startsWith(tgtPath))) {
    // case when the target path difers from the current hash route
    return path;
  }

  const tgtParams = new URLSearchParams(tgtQuery);
  const srcParams = new URLSearchParams(srcQuery);

  const queryChanged =
    Array.from(tgtParams.keys()).reduce(
      (changed, key) => changed || srcParams.get(key) !== tgtParams.get(key),
      false
    ) || tgtQuery.length !== srcQuery.length;

  if (queryChanged) {
    // if query has changed, reuse the path but overwrite the query
    return srcPath + "?" + tgtQuery;
  }

  return false;
}

function Forms({ useHash, path }) {
  const history = useHistory();
  const location = useLocation();

  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const redirection = useHash ? shouldRedirect(path) : path;
    if (redirection) history.push(redirection);
  }, []);

  useEffect(() => {
    const { pathname, search } = parseUrl(path);

    // check if router path and user path matches
    if (!location.pathname.startsWith(pathname) || search !== location.search) {
      // defer initialization to router resolution
      return;
    }

    // get locale from user path param
    const query = new URLSearchParams(location.search);
    initialize(null, query.get("locale"))
      .catch((err) => console.error(err))
      .finally(() => setIsInitializing(false));
  }, [path, location]);

  return (
    <Suspense fallback={() => <SplashScreen />}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <IdleTracker />
              {(isInitializing && <SplashScreen />) || <Routes />}
            </ThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </QueryParamProvider>
    </Suspense>
  );
}

export default function () {
  const Router = ({ children, useHash }) => {
    const Router = useHash ? HashRouter : MemoryRouter;
    return <Router>{children}</Router>;
  };

  return ({ el, attributes }) => {
    const { route, component } = attributes;
    const useHash = route !== undefined;
    const path = "/" + (route || component || "").replace(/^#?\//, "");

    ReactDOM.render(
      <Router useHash={useHash}>
        <Forms useHash={useHash} path={path} />
      </Router>,
      el
    );
  };
}
