import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { triggerPlausiblePageView } from "../lib/analytics/Plausible";
import { useQueryParams } from "hooks/useQueryParams";
import {
  triggerGoogleTagEvent,
  triggerGoogleTagPageView,
} from "lib/analytics/GoogleTag";

const locationURI = (location) => location.pathname + (location.search || "");

let onLocationChange = (function () {
  let from;
  let time;
  return (location) => {
    const url = locationURI(location);
    if (from === url) return;
    from = url;
    time = setTimeout(() => {
      clearTimeout(time);
      triggerGoogleTagPageView();
      triggerPlausiblePageView(location.search);
    }, 500);
  };
})();

/* Navigation breadcrumbs analytics */
export function useAnalytics() {
  const location = useLocation();
  const { cmplz_marketing, cmplz_statistics } = useQueryParams();

  /* manage consent GTM for marketing and statistics */
  useEffect(() => {
    if (cmplz_marketing === "allow") {
      triggerGoogleTagEvent("cmplz_event_marketing");
    }
    if (cmplz_statistics === "allow") {
      triggerGoogleTagEvent("cmplz_event_statistics");
    }
  }, []);

  /* Initial call to location tracking */
  useEffect(() => onLocationChange(location), []);

  /* Bind tracking effect to location changes */
  useEffect(() => {
    onLocationChange(location);
  }, [location]);

  /* Return a callback to turn off tracking */
  return () => {
    onLocationChange = () => {};
  };
}
