import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import { Button } from "ancient/components/Button";
import { Fiber2Mobiles } from "components/icons/Fiber2Mobiles";
import { Fiber3Mobiles } from "components/icons/Fiber3Mobiles";
import { Tiles } from "components/layouts/Tiles";
import { Trans, useTranslation } from "react-i18next";
import { theme } from "theme";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDataPlanSize } from "lib/helpers/format";
import { useMemo, useState } from "react";
import { Modal } from "components/layouts/Modal";
import { CheckField } from "components/fields/CheckField";
import { postChangePackNotSharedData } from "lib/api/tickets";
import { Spinner } from "components/Spinner";
import { AlertComponent } from "components/Alert";
import { useSharedDataContext } from "screens/ShareData/context";

const ModalChangePack = ({ bound, ...props }) => {
  const { t } = useTranslation();
  const [withLandLine, setWithLandLine] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { packs } = useSharedDataContext();
  const contractsRef = [bound.fiber.code].concat(
    bound.mobile.map(({ code }) => code)
  );
  const packActual = useMemo(
    () =>
      packs.find(
        ({ fiber_bandwidth, has_landline_phone, mobiles_in_pack }) =>
          bound.fiber.bandwidth === fiber_bandwidth &&
          bound.fiber.has_landline_phone === has_landline_phone &&
          bound.mobile.length === mobiles_in_pack
      ),
    [packs]
  );
  const targetPacks = useMemo(
    () =>
      packs.filter(
        ({ fiber_bandwidth, mobiles_in_pack }) =>
          fiber_bandwidth === 300 && bound.mobile.length === mobiles_in_pack
      ),
    [packs]
  );
  async function postTicket() {
    setIsSubmitting(true);
    const { status } = await postChangePackNotSharedData(
      contractsRef,
      withLandLine
    );
    if (status === 200) {
      setIsSubmitted(true);
    } else {
      setHasError(true);
    }
    setIsSubmitting(false);
  }
  return (
    <Modal title={t("share_data.contract.modal.title")} {...props}>
      <Box
        sx={{
          padding: "16px 24px ",
          display: "flex",
          flexDirection: "column",
          flex: "1",
          gap: 2,
        }}
      >
        {(() => {
          if (isSubmitting) {
            return (
              <Box sx={{ minWidth: 300 }}>
                <Spinner />
              </Box>
            );
          } else if (hasError || isSubmitted) {
            return (
              <AlertComponent
                type={hasError ? "error" : "success"}
                text={
                  hasError
                    ? t("common.errors.request_failed")
                    : t("share_data.succes.title")
                }
              />
            );
          } else {
            return (
              <>
                <Typography variant="body">
                  <Trans i18nKey={"share_data.contract.modal.description"}>
                    {{
                      actual_name: packActual?.name,
                      actual_price: packActual?.price,
                      n_mobiles: bound.mobile.length,
                    }}
                  </Trans>
                </Typography>
                <Tiles columns={1} spacing={4}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="body_bold">
                      {t("share_data.contract.modal.select_option")}
                    </Typography>
                    <CheckField
                      onChange={(event) =>
                        setWithLandLine(!event.target.checked)
                      }
                      infoLabel={t(
                        "share_data.contract.modal.option_wo_landline",
                        {
                          price: targetPacks.find(
                            ({ has_landline_phone }) => !has_landline_phone
                          )?.price,
                        }
                      )}
                      checked={withLandLine === false}
                    />
                    <CheckField
                      onChange={(event) =>
                        setWithLandLine(event.target.checked)
                      }
                      infoLabel={t(
                        "share_data.contract.modal.option_landline",
                        {
                          price: targetPacks.find(
                            ({ has_landline_phone }) => has_landline_phone
                          )?.price,
                        }
                      )}
                      checked={withLandLine === true}
                    />
                  </Box>

                  <Tiles>
                    <Button
                      onClick={postTicket}
                      disabled={withLandLine === undefined}
                    >
                      {t("share_data.contract.modal.accept")}
                    </Button>
                    <Button onClick={props.onClose}>
                      {t("common.cancel")}
                    </Button>
                  </Tiles>
                </Tiles>
              </>
            );
          }
        })()}
      </Box>
    </Modal>
  );
};

export const ContractsSharingData = ({ bound }) => {
  const { mobile: mobileContracts, fiber: fiberContract, boundId } = bound;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mobilesSharing = mobileContracts.length;
  const getIcon = (size) => {
    switch (mobilesSharing) {
      case 2:
        return <Fiber2Mobiles fontSize={size} />;
      case 3:
        return <Fiber3Mobiles fontSize={size} />;
      default:
        return <div />;
    }
  };

  const getLit = () => {
    let key = "share_data.contract.2_mob_desc";
    let mob3 = "";
    const data = mobileContracts[0].data;
    if (mobilesSharing === 3) {
      mob3 = mobileContracts[2].phone_number;
      key = "share_data.contract.3_mob_desc";
    }
    return (
      <Typography variant="body2">
        <Trans i18nKey={key}>
          {{
            mob1: mobileContracts[0].phone_number,
            mob2: mobileContracts[1].phone_number,
            mob3: mob3,
            fiber: fiberContract.description,
            address: fiberContract.address.street,
            data: formatDataPlanSize(data),
          }}
        </Trans>
      </Typography>
    );
  };

  const { t } = useTranslation();
  const history = useHistory();

  /* TODO  when show buttons , retore to xs { size: theme.spacing(10), mt: 9 } */
  const Iconstyle = useMediaQuery(theme.breakpoints.down("sm"))
    ? { size: theme.spacing(10), mt: 0 }
    : { size: theme.spacing(12), mt: 0 };

  return (
    <>
      <Box component={Card} width={1} py={2} p={4}>
        <Box display="flex">
          <Box mt={Iconstyle.mt}>{getIcon(Iconstyle.size)}</Box>
          <Box ml={5} mt={2}>
            <Tiles columns={1}>
              {getLit()}
              <Tiles>
                <Button
                  onClick={() =>
                    history.push("share-data/limit-data/" + boundId)
                  }
                >
                  {t("common.edit")}
                </Button>
                {fiberContract.active_product_code === "SE_SC_REC_BA_F_600" && (
                  <Button onClick={() => setIsModalOpen(true)}>
                    {t("share_data.contract.change_pack")}
                  </Button>
                )}
              </Tiles>
            </Tiles>
          </Box>
        </Box>
      </Box>
      <ModalChangePack
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        bound={bound}
      />
    </>
  );
};
