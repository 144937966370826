import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import { FormHelperText, styled, Typography } from "@mui/material";

const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  "color": theme.palette.grey[900],
  "marginLeft": "0px",
  "display": "flex",
  "alignItems": "center",
  "gap": "4px",
  "alignSelf": "stretch",
  "&.Mui-disabled": { color: theme.palette.grey[900] },
  "&.Mui-error": { color: theme.palette.grey[900] },
}));

export const InputSubInfo = ({ id, label }) => (
  <StyledHelperText id={id} style={{ marginTop: "4px", marginBottom: "0px" }}>
    <InfoIcon fontSize="small" />
    <Typography variant="body_small">{label}</Typography>
  </StyledHelperText>
);
