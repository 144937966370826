import { Box, Typography, useMediaQuery } from "@mui/material";
import { Spinner } from "components/Spinner";
import { useApplicationContext } from "hooks/useApplicationContext";
import { useStore } from "hooks/useStore";
import { capitalize } from "lib/helpers/string";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import celebrationSrc from "../../img/celebration.webm";
import { buildAnalyticsParamFromState } from "./shared/buildAnalyticsParamFromState";

export const Thanks = () => {
  const history = useHistory();
  const store = useStore((state) => state);
  const { t } = useTranslation();
  const { currentUser } = useApplicationContext();
  const loggedIn = Boolean(currentUser);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const username = currentUser
    ? currentUser.first_name
    : capitalize(store.formStepDataByKey["partner/personal-data"].name);

  const onlyMembership = store.lines.length === 0;

  const [, setFormParam] = useQueryParam("form", StringParam);

  useEffect(() => {
    if (loggedIn) {
      localStorage.setItem(
        "flash-message",
        t("funnel.signup.thanks.flash_message")
      );

      store.resetSignupFlow();
      history.push("/home");
    }
  }, [loggedIn]);

  useEffect(() => {
    setFormParam(buildAnalyticsParamFromState(store, "thanks"));
  }, [store, setFormParam]);

  if (loggedIn) {
    return <Spinner />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: isMobile ? "-24px" : "-48px",
        ml: isMobile ? "-16px" : "-64px",
        mr: isMobile && "-16px",
        bgcolor: "grey.100",
      }}
    >
      <video
        width={isMobile ? "100%" : "960px"}
        height={isMobile ? "calc(100vw*9/16)" : "100%"}
        autoPlay
        muted
        loop
        id="thanks"
      >
        <source id="thanks_source" src={celebrationSrc} type="video/webm" />
      </video>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          position: "relative",
          bottom: isMobile ? "64px" : "128px",
          maxWidth: "900px",
          bgcolor: "grey.100",
          px: isMobile ? 4 : 11,
          py: isMobile ? 2 : 9,
        }}
      >
        <Typography variant={isMobile ? "h3" : "h2"}>
          <Trans i18nKey="funnel.signup.thanks.welcome">{{ username }}</Trans>
        </Typography>
        <Typography variant="body_xl_bold">
          {t("funnel.signup.thanks.welcome_subtitle")}
        </Typography>
        <Typography variant="body_large_bold">
          {t("funnel.signup.thanks.now_what_title")}
        </Typography>
        <Typography variant="body_large">
          {onlyMembership
            ? t("funnel.signup.thanks.now_what_body_only_membership")
            : t("funnel.signup.thanks.now_what_body")}
        </Typography>
        <Typography
          sx={{ textAlign: "center" }}
          variant="link_small"
          onClick={() => history.push("/web-somconnexio")}
        >
          {t("funnel.signup.thanks.go_home")}
        </Typography>
      </Box>
    </Box>
  );
};
