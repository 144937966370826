import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Radio,
  FormControlLabel,
  RadioGroup,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Button } from "ancient/components/Button";
import { Tiles } from "components/layouts/Tiles";
import { ModalLayout } from "ancient/components/layouts/ModalLayout";
import { Separator } from "components/Separator";
import { formatDataPlanSize } from "lib/helpers/format";

const useStyles = makeStyles((theme) => ({
  titleBox: {
    display: "block",
    justifyContent: "flex-start",
  },
  AccordionSummary: {
    textTransform: "uppercase",
    background: theme.palette.background.light,
    margin: "0px",
  },
  AccordionDetail: {
    display: "grid",
  },
  Accordion: {
    margin: "0px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
    width: "100%",
  },
}));

export const ModalLimitData = ({
  isOpen,
  onClose,
  onConfirm,
  mobileLine,
  currentLineLimit,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const [selectedLineLimit, setSelectedLineLimit] = useState(null);
  useEffect(() => {
    setSelectedLineLimit(currentLineLimit);
  }, [currentLineLimit]);

  const formatLimitLabel = (val) => {
    if (val === 100) {
      return "sense límits";
    } else {
      const gigabytes = formatDataPlanSize(Math.round((val * 51200) / 100));
      return "limitar a " + gigabytes;
    }
  };

  const availableLimits = [10, 25, 50, 75, 100];

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <Typography variant="h5">
        {mobileLine
          ? t(`share_data.limit_data.modal.title`, {
              mobile_line: mobileLine.phone_number,
            })
          : t()}
      </Typography>

      <Accordion className={styles.Accordion} expanded={true}>
        <AccordionSummary className={styles.AccordionSummary}>
          <Typography variant="subtitle1" color="textSecondary">
            {t("share_data.limit_data.modal.data_amount")}
          </Typography>
        </AccordionSummary>
        <RadioGroup
          value={selectedLineLimit}
          onChange={(ev) => setSelectedLineLimit(Number(ev.target.value))}
        >
          {availableLimits.map((limit, index) => {
            if (index < 4) {
              return (
                <AccordionDetails
                  key={index}
                  className={styles.AccordionDetail}
                >
                  <Tiles columns={1}>
                    <FormControlLabel
                      value={limit}
                      control={<Radio disabled={currentLineLimit === limit} />}
                      label={formatLimitLabel(limit)}
                    />
                    <Separator />
                  </Tiles>
                </AccordionDetails>
              );
            } else {
              return (
                <AccordionDetails
                  key={index}
                  className={styles.AccordionDetail}
                >
                  <Tiles columns={1}>
                    <FormControlLabel
                      value={limit}
                      control={<Radio />}
                      label={formatLimitLabel(limit)}
                    />
                  </Tiles>
                </AccordionDetails>
              );
            }
          })}
        </RadioGroup>
      </Accordion>
      <Box display="flex" flexDirection="row">
        <Box m={1}>
          <Button onClick={() => onConfirm(selectedLineLimit)}>
            {t("common.accept")}
          </Button>
        </Box>
        <Box m={1} display="flex" justifyContent="center">
          <Button onClick={onClose}> {t("common.cancel")} </Button>
        </Box>
      </Box>
    </ModalLayout>
  );
};
