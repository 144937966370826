import { useTranslation } from "react-i18next";

export const genders = ["male", "female", "other"];

export const previousBroadbandServices = ["adsl", "fiber"];

export const previousMobileServices = ["contract", "prepaid"];

export const nationalities = [
  {
    label: "Spain",
    value: "ES",
  },
  {
    label: "Andorra",
    value: "AD",
  },
  {
    label: "United Arab Emirates",
    value: "AE",
  },
  {
    label: "Afghanistan",
    value: "AF",
  },
  {
    label: "Antigua and Barbuda",
    value: "AG",
  },
  {
    label: "Anguilla",
    value: "AI",
  },
  {
    label: "Albania",
    value: "AL",
  },
  {
    label: "Armenia",
    value: "AM",
  },
  {
    label: "Angola",
    value: "AO",
  },
  {
    label: "Antarctica",
    value: "AQ",
  },
  {
    label: "Argentina",
    value: "AR",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Austria",
    value: "AT",
  },
  {
    label: "Australia",
    value: "AU",
  },
  {
    label: "Aruba",
    value: "AW",
  },
  {
    label: "Åland Islands",
    value: "AX",
  },
  {
    label: "Azerbaijan",
    value: "AZ",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "BA",
  },
  {
    label: "Barbados",
    value: "BB",
  },
  {
    label: "Bangladesh",
    value: "BD",
  },
  {
    label: "Belgium",
    value: "BE",
  },
  {
    label: "Burkina Faso",
    value: "BF",
  },
  {
    label: "Bulgaria",
    value: "BG",
  },
  {
    label: "Bahrain",
    value: "BH",
  },
  {
    label: "Burundi",
    value: "BI",
  },
  {
    label: "Benin",
    value: "BJ",
  },
  {
    label: "Saint Barthélémy",
    value: "BL",
  },
  {
    label: "Bermuda",
    value: "BM",
  },
  {
    label: "Brunei Darussalam",
    value: "BN",
  },
  {
    label: "Bolivia",
    value: "BO",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "BQ",
  },
  {
    label: "Brazil",
    value: "BR",
  },
  {
    label: "Bahamas",
    value: "BS",
  },
  {
    label: "Bhutan",
    value: "BT",
  },
  {
    label: "Bouvet Island",
    value: "BV",
  },
  {
    label: "Botswana",
    value: "BW",
  },
  {
    label: "Belarus",
    value: "BY",
  },
  {
    label: "Belize",
    value: "BZ",
  },
  {
    label: "Canada",
    value: "CA",
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "CC",
  },
  {
    label: "Central African Republic",
    value: "CF",
  },
  {
    label: "Democratic Republic of the Congo",
    value: "CD",
  },
  {
    label: "Congo",
    value: "CG",
  },
  {
    label: "Switzerland",
    value: "CH",
  },
  {
    label: "Côte d'Ivoire",
    value: "CI",
  },
  {
    label: "Cook Islands",
    value: "CK",
  },
  {
    label: "Chile",
    value: "CL",
  },
  {
    label: "Cameroon",
    value: "CM",
  },
  {
    label: "China",
    value: "CN",
  },
  {
    label: "Colombia",
    value: "CO",
  },
  {
    label: "Costa Rica",
    value: "CR",
  },
  {
    label: "Cuba",
    value: "CU",
  },
  {
    label: "Cape Verde",
    value: "CV",
  },
  {
    label: "Curaçao",
    value: "CW",
  },
  {
    label: "Christmas Island",
    value: "CX",
  },
  {
    label: "Cyprus",
    value: "CY",
  },
  {
    label: "Czech Republic",
    value: "CZ",
  },
  {
    label: "Germany",
    value: "DE",
  },
  {
    label: "Djibouti",
    value: "DJ",
  },
  {
    label: "Denmark",
    value: "DK",
  },
  {
    label: "Dominica",
    value: "DM",
  },
  {
    label: "Dominican Republic",
    value: "DO",
  },
  {
    label: "Algeria",
    value: "DZ",
  },
  {
    label: "Ecuador",
    value: "EC",
  },
  {
    label: "Estonia",
    value: "EE",
  },
  {
    label: "Egypt",
    value: "EG",
  },
  {
    label: "Western Sahara",
    value: "EH",
  },
  {
    label: "Eritrea",
    value: "ER",
  },
  {
    label: "Ethiopia",
    value: "ET",
  },
  {
    label: "Finland",
    value: "FI",
  },
  {
    label: "Fiji",
    value: "FJ",
  },
  {
    label: "Falkland Islands",
    value: "FK",
  },
  {
    label: "Micronesia",
    value: "FM",
  },
  {
    label: "Faroe Islands",
    value: "FO",
  },
  {
    label: "France",
    value: "FR",
  },
  {
    label: "Gabon",
    value: "GA",
  },
  {
    label: "Grenada",
    value: "GD",
  },
  {
    label: "Georgia",
    value: "GE",
  },
  {
    label: "French Guiana",
    value: "GF",
  },
  {
    label: "Ghana",
    value: "GH",
  },
  {
    label: "Gibraltar",
    value: "GI",
  },
  {
    label: "Guernsey",
    value: "GG",
  },
  {
    label: "Greenland",
    value: "GL",
  },
  {
    label: "Gambia",
    value: "GM",
  },
  {
    label: "Guinea",
    value: "GN",
  },
  {
    label: "Guadeloupe",
    value: "GP",
  },
  {
    label: "Equatorial Guinea",
    value: "GQ",
  },
  {
    label: "Greece",
    value: "GR",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "GS",
  },
  {
    label: "Guatemala",
    value: "GT",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Guinea-Bissau",
    value: "GW",
  },
  {
    label: "Guyana",
    value: "GY",
  },
  {
    label: "Hong Kong",
    value: "HK",
  },
  {
    label: "Heard Island and McDonald Islands",
    value: "HM",
  },
  {
    label: "Honduras",
    value: "HN",
  },
  {
    label: "Croatia",
    value: "HR",
  },
  {
    label: "Haiti",
    value: "HT",
  },
  {
    label: "Hungary",
    value: "HU",
  },
  {
    label: "Indonesia",
    value: "ID",
  },
  {
    label: "Ireland",
    value: "IE",
  },
  {
    label: "Israel",
    value: "IL",
  },
  {
    label: "Isle of Man",
    value: "IM",
  },
  {
    label: "India",
    value: "IN",
  },
  {
    label: "British Indian Ocean Territory",
    value: "IO",
  },
  {
    label: "Iraq",
    value: "IQ",
  },
  {
    label: "Iran",
    value: "IR",
  },
  {
    label: "Iceland",
    value: "IS",
  },
  {
    label: "Italy",
    value: "IT",
  },
  {
    label: "Jersey",
    value: "JE",
  },
  {
    label: "Jamaica",
    value: "JM",
  },
  {
    label: "Jordan",
    value: "JO",
  },
  {
    label: "Japan",
    value: "JP",
  },
  {
    label: "Kenya",
    value: "KE",
  },
  {
    label: "Kyrgyzstan",
    value: "KG",
  },
  {
    label: "Cambodia",
    value: "KH",
  },
  {
    label: "Kiribati",
    value: "KI",
  },
  {
    label: "Comoros",
    value: "KM",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "KN",
  },
  {
    label: "North Korea",
    value: "KP",
  },
  {
    label: "South Korea",
    value: "KR",
  },
  {
    label: "Kuwait",
    value: "KW",
  },
  {
    label: "Cayman Islands",
    value: "KY",
  },
  {
    label: "Kazakhstan",
    value: "KZ",
  },
  {
    label: "Laos",
    value: "LA",
  },
  {
    label: "Lebanon",
    value: "LB",
  },
  {
    label: "Saint Lucia",
    value: "LC",
  },
  {
    label: "Liechtenstein",
    value: "LI",
  },
  {
    label: "Sri Lanka",
    value: "LK",
  },
  {
    label: "Liberia",
    value: "LR",
  },
  {
    label: "Lesotho",
    value: "LS",
  },
  {
    label: "Lithuania",
    value: "LT",
  },
  {
    label: "Luxembourg",
    value: "LU",
  },
  {
    label: "Latvia",
    value: "LV",
  },
  {
    label: "Libya",
    value: "LY",
  },
  {
    label: "Morocco",
    value: "MA",
  },
  {
    label: "Monaco",
    value: "MC",
  },
  {
    label: "Moldova",
    value: "MD",
  },
  {
    label: "Montenegro",
    value: "ME",
  },
  {
    label: "Saint Martin (French part)",
    value: "MF",
  },
  {
    label: "Madagascar",
    value: "MG",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Macedonia, the former Yugoslav Republic of",
    value: "MK",
  },
  {
    label: "Mali",
    value: "ML",
  },
  {
    label: "Myanmar",
    value: "MM",
  },
  {
    label: "Mongolia",
    value: "MN",
  },
  {
    label: "Macau",
    value: "MO",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Martinique",
    value: "MQ",
  },
  {
    label: "Mauritania",
    value: "MR",
  },
  {
    label: "Montserrat",
    value: "MS",
  },
  {
    label: "Malta",
    value: "MT",
  },
  {
    label: "Mauritius",
    value: "MU",
  },
  {
    label: "Maldives",
    value: "MV",
  },
  {
    label: "Malawi",
    value: "MW",
  },
  {
    label: "Mexico",
    value: "MX",
  },
  {
    label: "Malaysia",
    value: "MY",
  },
  {
    label: "Mozambique",
    value: "MZ",
  },
  {
    label: "Namibia",
    value: "NA",
  },
  {
    label: "New Caledonia",
    value: "NC",
  },
  {
    label: "Niger",
    value: "NE",
  },
  {
    label: "Norfolk Island",
    value: "NF",
  },
  {
    label: "Nigeria",
    value: "NG",
  },
  {
    label: "Nicaragua",
    value: "NI",
  },
  {
    label: "Netherlands",
    value: "NL",
  },
  {
    label: "Norway",
    value: "NO",
  },
  {
    label: "Nepal",
    value: "NP",
  },
  {
    label: "Nauru",
    value: "NR",
  },
  {
    label: "Niue",
    value: "NU",
  },
  {
    label: "New Zealand",
    value: "NZ",
  },
  {
    label: "Oman",
    value: "OM",
  },
  {
    label: "Panama",
    value: "PA",
  },
  {
    label: "Peru",
    value: "PE",
  },
  {
    label: "French Polynesia",
    value: "PF",
  },
  {
    label: "Papua New Guinea",
    value: "PG",
  },
  {
    label: "Philippines",
    value: "PH",
  },
  {
    label: "Pakistan",
    value: "PK",
  },
  {
    label: "Poland",
    value: "PL",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "PM",
  },
  {
    label: "Pitcairn Islands",
    value: "PN",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "State of Palestine",
    value: "PS",
  },
  {
    label: "Portugal",
    value: "PT",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Paraguay",
    value: "PY",
  },
  {
    label: "Qatar",
    value: "QA",
  },
  {
    label: "Réunion",
    value: "RE",
  },
  {
    label: "Romania",
    value: "RO",
  },
  {
    label: "Serbia",
    value: "RS",
  },
  {
    label: "Russian Federation",
    value: "RU",
  },
  {
    label: "Rwanda",
    value: "RW",
  },
  {
    label: "Saudi Arabia",
    value: "SA",
  },
  {
    label: "Solomon Islands",
    value: "SB",
  },
  {
    label: "Seychelles",
    value: "SC",
  },
  {
    label: "Sudan",
    value: "SD",
  },
  {
    label: "Sweden",
    value: "SE",
  },
  {
    label: "Singapore",
    value: "SG",
  },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "SH",
  },
  {
    label: "Slovenia",
    value: "SI",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "SJ",
  },
  {
    label: "Slovakia",
    value: "SK",
  },
  {
    label: "Sierra Leone",
    value: "SL",
  },
  {
    label: "San Marino",
    value: "SM",
  },
  {
    label: "Senegal",
    value: "SN",
  },
  {
    label: "Somalia",
    value: "SO",
  },
  {
    label: "Suriname",
    value: "SR",
  },
  {
    label: "South Sudan",
    value: "SS",
  },
  {
    label: "São Tomé and Príncipe",
    value: "ST",
  },
  {
    label: "El Salvador",
    value: "SV",
  },
  {
    label: "Sint Maarten (Dutch part)",
    value: "SX",
  },
  {
    label: "Syria",
    value: "SY",
  },
  {
    label: "Swaziland",
    value: "SZ",
  },
  {
    label: "Turks and Caicos Islands",
    value: "TC",
  },
  {
    label: "Chad",
    value: "TD",
  },
  {
    label: "French Southern Territories",
    value: "TF",
  },
  {
    label: "Togo",
    value: "TG",
  },
  {
    label: "Thailand",
    value: "TH",
  },
  {
    label: "Tajikistan",
    value: "TJ",
  },
  {
    label: "Tokelau",
    value: "TK",
  },
  {
    label: "Turkmenistan",
    value: "TM",
  },
  {
    label: "Tunisia",
    value: "TN",
  },
  {
    label: "Tonga",
    value: "TO",
  },
  {
    label: "Timor-Leste",
    value: "TL",
  },
  {
    label: "Turkey",
    value: "TR",
  },
  {
    label: "Trinidad and Tobago",
    value: "TT",
  },
  {
    label: "Tuvalu",
    value: "TV",
  },
  {
    label: "Taiwan",
    value: "TW",
  },
  {
    label: "Tanzania",
    value: "TZ",
  },
  {
    label: "Ukraine",
    value: "UA",
  },
  {
    label: "Uganda",
    value: "UG",
  },
  {
    label: "United Kingdom",
    value: "GB",
  },
  {
    label: "USA Minor Outlying Islands",
    value: "UM",
  },
  {
    label: "United States",
    value: "US",
  },
  {
    label: "Uruguay",
    value: "UY",
  },
  {
    label: "Uzbekistan",
    value: "UZ",
  },
  {
    label: "Holy See (Vatican City State)",
    value: "VA",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "VC",
  },
  {
    label: "Venezuela",
    value: "VE",
  },
  {
    label: "Virgin Islands (British)",
    value: "VG",
  },
  {
    label: "Virgin Islands (USA)",
    value: "VI",
  },
  {
    label: "Vietnam",
    value: "VN",
  },
  {
    label: "Vanuatu",
    value: "VU",
  },
  {
    label: "Wallis and Futuna",
    value: "WF",
  },
  {
    label: "Samoa",
    value: "WS",
  },
  {
    label: "Yemen",
    value: "YE",
  },
  {
    label: "Mayotte",
    value: "YT",
  },
  {
    label: "South Africa",
    value: "ZA",
  },
  {
    label: "Zambia",
    value: "ZM",
  },
  {
    label: "Zimbabwe",
    value: "ZW",
  },
  {
    label: "Kosovo",
    value: "XK",
  },
];

export const states = [
  {
    value: "PM",
    label: "Illes Balears",
  },
  {
    value: "GI",
    label: "Girona",
  },
  {
    value: "L",
    label: "Lleida",
  },
  {
    value: "SS",
    label: "Gipuzkoa",
  },
  {
    value: "BI",
    label: "Bizkaia",
  },
  {
    value: "A",
    label: "Alicante-Alacant",
  },
  {
    value: "CS",
    label: "Castellón-Castelló",
  },
  {
    value: "V",
    label: "Valencia-València",
  },
  {
    value: "C",
    label: "A Coruña",
  },
  {
    value: "OR",
    label: "Ourense",
  },
  {
    value: "NA",
    label: "Navarra",
  },
  {
    value: "VI",
    label: "Araba/Álava",
  },
  {
    value: "AB",
    label: "Albacete",
  },
  {
    value: "AL",
    label: "Almería",
  },
  {
    value: "O",
    label: "Asturias",
  },
  {
    value: "AV",
    label: "Ávila",
  },
  {
    value: "BA",
    label: "Badajoz",
  },
  {
    value: "B",
    label: "Barcelona",
  },
  {
    value: "BU",
    label: "Burgos",
  },
  {
    value: "CC",
    label: "Cáceres",
  },
  {
    value: "CA",
    label: "Cádiz",
  },
  {
    value: "S",
    label: "Cantabria",
  },
  {
    value: "CE",
    label: "Ceuta",
  },
  {
    value: "CR",
    label: "Ciudad Real",
  },
  {
    value: "CO",
    label: "Córdoba",
  },
  {
    value: "CU",
    label: "Cuenca",
  },
  {
    value: "GR",
    label: "Granada",
  },
  {
    value: "GU",
    label: "Guadalajara",
  },
  {
    value: "H",
    label: "Huelva",
  },
  {
    value: "HU",
    label: "Huesca",
  },
  {
    value: "J",
    label: "Jaén",
  },
  {
    value: "LE",
    label: "León",
  },
  {
    value: "LU",
    label: "Lugo",
  },
  {
    value: "M",
    label: "Madrid",
  },
  {
    value: "MA",
    label: "Málaga",
  },
  {
    value: "ME",
    label: "Melilla",
  },
  {
    value: "MU",
    label: "Murcia",
  },
  {
    value: "P",
    label: "Palencia",
  },
  {
    value: "GC",
    label: "Las Palmas",
  },
  {
    value: "PO",
    label: "Pontevedra",
  },
  {
    value: "LO",
    label: "La Rioja",
  },
  {
    value: "SA",
    label: "Salamanca",
  },
  {
    value: "TF",
    label: "Santa Cruz de Tenerife",
  },
  {
    value: "SG",
    label: "Segovia",
  },
  {
    value: "SE",
    label: "Sevilla",
  },
  {
    value: "SO",
    label: "Soria",
  },
  {
    value: "T",
    label: "Tarragona",
  },
  {
    value: "TE",
    label: "Teruel",
  },
  {
    value: "TO",
    label: "Toledo",
  },
  {
    value: "VA",
    label: "Valladolid",
  },
  {
    value: "Z",
    label: "Zaragoza",
  },
  {
    value: "ZA",
    label: "Zamora",
  },
].sort((a, b) => a.label.localeCompare(b.label));

export const WeCallYouSelections = () => {
  const { t } = useTranslation();
  return {
    ScheduleOptions: [
      { value: "1012", label: "10h-12h" },
      { value: "1214", label: "12h-14h" },
      { value: "1416", label: "14h-16h" },
      { value: "1618", label: "16h-18h" },
    ],
    CompanySizeOptions: [
      { value: "1-5", label: t("help_we_call_you.modal.company_size.1_5") },
      { value: "6-15", label: t("help_we_call_you.modal.company_size.6_15") },
      { value: "16-25", label: t("help_we_call_you.modal.company_size.16_25") },
      { value: "25+", label: t("help_we_call_you.modal.company_size.25") },
    ],
    reasonOptions: [
      {
        label: t("help_we_call_you.modal.reason.tariffs"),
        value: "pagina_tarifes",
      },
      {
        label: t("help_we_call_you.modal.reason.help"),
        value: "ajuda",
      },
      {
        label: t("help_we_call_you.modal.reason.change_request"),
        value: "canvis",
      },
      {
        label: t("help_we_call_you.modal.reason.contract_info"),
        value: "contractar_info",
      },
      {
        label: t("help_we_call_you.modal.reason.info_coop"),
        value: "info_coop",
      },
      {
        label: t("help_we_call_you.modal.reason.more_service"),
        value: "mes_serveis",
      },
      {
        label: t("help_we_call_you.modal.reason.others"),
        value: "altres",
      },
    ],
  };
};
