export function triggerGoogleTagEvent(event, additionalData = {}) {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: event,
    ...additionalData,
  });
}

export function triggerGoogleTagPageView() {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "pageviewReact",
  });
}
