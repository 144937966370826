import { Button } from "ancient/components/Button";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { useSharedDataContext } from "../context";
import { Tiles } from "components/layouts/Tiles";
import { ContractsSharingData } from "ancient/components/views/ContractSharing";
import { useHistory } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { Card } from "ancient/components/Card";
import { useCatalog } from "hooks/queries/useTariffs";
import { useEffect } from "react";
import { Spinner } from "components/Spinner";
import { getAllProducts } from "hooks/queries/helper/catalogMaker";
import { Link } from "components/Link";
const useStyles = makeStyles((theme) => ({
  warningIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(9),
  },
  advice: { fontStyle: "italic", fontWeight: "bold" },
}));
const Advice = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { optionalContracts } = useSharedDataContext();
  const n_mobiles = optionalContracts.mobile.length;
  const n_fibers = optionalContracts.fiber.length;

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <WarningIcon className={styles.warningIcon} />
        <Typography variant="h6" align="center" className={styles.advice}>
          {t("share_data.advice")}
        </Typography>
        <Box mt={5}>
          <Typography variant="h6">
            <Trans i18nKey={"share_data.advice_explain"} />
          </Typography>

          <Card>
            <Typography align="center">
              <Trans i18nKey={"share_data.advice_have"}>
                {{
                  n_mobiles,
                  n_fibers,
                }}
              </Trans>
            </Typography>
          </Card>
          <Box
            sx={{
              fontStyle: "italic",
              mt: 1,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body_small">
              {t("share_data.advice_advice")}
            </Typography>
            <Typography variant="body_small">
              <Trans i18nKey={"share_data.advice_advice_2"}>
                <Link
                  target="_blank"
                  to={"mailto:" + t("common.assistance_email")}
                />
              </Trans>
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              history.push("/join");
            }}
          >
            {t("common.contract")}
          </Button>
        </Box>
      </Box>
    </>
  );
};
export const Initial = () => {
  const {
    sharingContracts,
    setShouldShowStepper,
    setPacks,
    setAllCatalog,
    setIsCombineInitialized,
    optionalContracts,
  } = useSharedDataContext();
  setShouldShowStepper(false);
  const { t } = useTranslation();
  const history = useHistory();
  const isSharing = Boolean(sharingContracts.length);

  const canShare =
    Boolean(optionalContracts.fiber.length) &&
    optionalContracts.mobile.length > 1;
  const isCatalogCalled = canShare || isSharing;
  const onClickCombine = () => {
    setIsCombineInitialized(true);
    history.push("share-data/mobiles");
  };
  const {
    data: catalog,
    isLoading: isCatalogLoading,
    isSuccess: isCatalogLoaded,
  } = useCatalog({
    automatically_run: isCatalogCalled,
  });
  useEffect(() => {
    if (isCatalogLoaded) {
      setPacks(catalog.packs);
      setAllCatalog(getAllProducts(catalog));
    }
    return;
  }, [isCatalogLoaded]);

  return (
    <>
      <Typography variant="body2">{t("share_data.description")}</Typography>
      {isCatalogLoading ? (
        <Box mt={5}>
          <Spinner />
        </Box>
      ) : (
        <>
          {canShare ? (
            <>
              <Box mt={5}>
                <Typography variant="h6">{t("share_data.select")}</Typography>
              </Box>
              <Tiles columns="2" spacing={4}>
                <div />
                <Button onClick={onClickCombine}>
                  {t("share_data.combine")}
                </Button>
              </Tiles>
            </>
          ) : !isSharing ? (
            <Advice />
          ) : (
            <></>
          )}
          {isSharing && (
            <>
              <Box mt={5}>
                <Typography variant="h6">
                  {t("share_data.contracts_sharing")}
                </Typography>
              </Box>
              {sharingContracts.map((bound) => (
                <ContractsSharingData bound={bound} />
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};
