import { isMatch, isAfter, parse, intervalToDuration } from "date-fns";
import {
  spainIdType,
  validateSpanishId,
  validCIF,
  validDNI,
  validNIE,
} from "spain-id";
import ibanLib from "iban";
import { phoneIsAlreadyHired, sponsorIsValid } from "lib/api/user";
import { Link } from "components/Link";

const guard =
  (fn) =>
  (val, ...args) =>
    val ? fn(val, ...args) : undefined;

const sanitizeVal = (value) => {
  return String(value).trim().replace(/ /g, "");
};

export const composeValidators =
  (...validators) =>
  (...args) =>
    validators.reduce(
      (error, validator) => error || validator(...args),
      undefined
    );

export const required = (value) => {
  // || (typeof value === "number" && isNaN(value))
  return value === null || value === void 0 || String(value).trim().length === 0
    ? "required"
    : void 0;
};

/*Extract from codesandbox.io/s/wy7z7q5zx5*/
const simpleMemorize = (fn) => {
  let lastArg;
  let lastResult;
  return (arg) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

export const requiredLastName = (value) =>
  required(value) ? "required_last_name" : undefined;

export const matchDateFormat = guard((value) =>
  isMatch(value, "d/M/y") ? undefined : "match_date_format"
);

export const isEmail = guard((value) => {
  return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value);
});

export const matchEmailFormat = guard((value) => {
  return isEmail(value) ? undefined : "match_email_format";
});

export const matchVatFormat = guard((value) =>
  validateSpanishId(value) ? undefined : "match_vat_format"
);

export const matchCompanyVatFormat = guard((value) =>
  validCIF(value) ? undefined : "match_vat_company_format"
);

export const matchPersonVatFormat = guard((value) => {
  return validDNI(value) || validNIE(value)
    ? undefined
    : spainIdType(value) === "cif"
    ? ["cif_in_person", {}, (t) => <Link to={t("urls.companies")} />]
    : "match_vat_personal_format";
});

export const mustMatchOther = (other) =>
  guard((value, fields) => {
    return value === fields[other] ? undefined : "must_match_other";
  });

export const mustNotBe =
  ({ values, errorKey }) =>
  (value) => {
    if (!value) {
      return undefined;
    }

    const message = errorKey || ["must_not_be", { value: values[0] }];

    return values
      .map((value) => value.toLowerCase())
      .includes(value.toLowerCase())
      ? message
      : undefined;
  };

export const matchIbanFormat = guard((value) => {
  return ibanLib.isValid(value.replace(/ /g, ""))
    ? undefined
    : "match_iban_format";
});

export const validBirthdate = guard((value) => {
  const parsedValue = parse(value, "dd/MM/yyyy", new Date());
  return isAfter(parsedValue, new Date(1900, 1, 1))
    ? undefined
    : "invalid_date_format";
});

export const mustBeAdult = guard((value) => {
  const parsedValue = parse(value, "dd/MM/yyyy", new Date());
  const age = intervalToDuration({ start: parsedValue, end: new Date() });
  return age.years >= 18 ? undefined : "underaged";
});

export const validMobilePhone = guard((value) => {
  return /^[67]{1}(\d){8}$/.test(sanitizeVal(value))
    ? undefined
    : "invalid_mobile_number";
});

export const validLandLinePhone = guard((value) => {
  return /^[89]{1}(\d){8}$/.test(sanitizeVal(value))
    ? undefined
    : "invalid_landline_number";
});

export const validPhone = guard((value) => {
  return validMobilePhone(value) === undefined ||
    validLandLinePhone(value) === undefined
    ? undefined
    : "invalid_phone";
});

export const checkPhoneIsHired = simpleMemorize(async (value) => {
  return (await phoneIsAlreadyHired(value)) ? "existing_phone" : undefined;
});

export const validateSponsor = async ({ sponsor_code, sponsor_vat }) =>
  await sponsorIsValid(sponsor_code, sponsor_vat);
