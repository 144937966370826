import React from "react";
import { Box, Typography } from "@mui/material";
import { DialogSuccesForm } from "components/dialogs/DialogSuccesForm";
import { Button } from "components/Button";
import { SmallCloud } from "components/icons/Clouds";
import { BigCloud } from "components/icons/Clouds";
import { Forest } from "components/icons/Forest";
import { Trans } from "react-i18next";

const FormSucces = ({
  onClose,
  type,
  backgroundColor,
  title,
  message,
  finalMessage,
  buttonLabel,
  companyMessage,
}) => {
  const containerWidth = type === "fullDisplay" ? "100%" : "500px";

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: { backgroundColor },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: { containerWidth },
        padding: type === "fullDisplay" ? "96px 24px" : "96px",
        height: type === "fullDisplay" ? "100%" : "auto",
        overflow: "hidden",
        borderBottom: `4px solid ${theme.palette.grey[900]}`,
      })}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "30px",
          maxWidth: "500px",
          width: "100%",
          padding: "30px 20px 0",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: type === "fullDisplay" ? 50 : 70,
            transform: "translate(-50%, -50%)",
          }}
        >
          <SmallCloud />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: type === "fullDisplay" ? 50 : 70,
            transform: "translate(50%, -50%)",
          }}
        >
          <BigCloud />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          {message && (
            <Typography variant="body" gutterBottom>
              {message}
            </Typography>
          )}
          <Typography variant="body" gutterBottom>
            {finalMessage}
          </Typography>
        </Box>
        <DialogSuccesForm
          text={<Trans i18nKey="succes_form.great_day_message" />}
        />
        {companyMessage && (
          <Box
            sx={{
              textAlign: "center",
              mt: 3,
              mb: 2,
            }}
          >
            <Typography variant="body" gutterBottom>
              {companyMessage}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 3, mb: 5, width: "300px" }}>
        <Button fullWidth onClick={onClose}>
          {buttonLabel}
        </Button>
      </Box>
      {type !== "fullDisplay" && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Forest />
        </Box>
      )}
    </Box>
  );
};

export default FormSucces;
