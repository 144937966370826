import { Typography } from "@mui/material";
import { Link } from "components/Link";
import { Footer } from "components/layouts/Footer";
import { useTranslation } from "react-i18next";
import { getUrlQuery } from "lib/helpers/urls";
import { useQueryParams } from "hooks/useQueryParams";
import { Trans } from "react-i18next";

export const SetTariffFooter = () => {
  const { i18n } = useTranslation();
  const qp = useQueryParams();
  function resolvePersonalizedUrl() {
    if (process.env.REACT_APP_FORM_BUILD) {
      const origin = window.location.origin;
      const lang = i18n.language;
      const isCompany = qp?.is_company;
      const url = `${origin}/${lang === "es" ? "es/" : ""}`;
      if (isCompany) {
        return `${url}${
          lang === "ca"
            ? "empreses/constructor-de-tarifes"
            : "empresas/constructor-de-tarifas "
        }`;
      }
      return `${url}${
        lang === "ca" ? "constructor-de-tarifes" : "constructor-de-tarifas"
      }`;
    } else {
      return getUrlQuery(`/tariffs/personalized`, qp);
    }
  }
  return (
    <Footer>
      <Typography variant="body_small">
        <Trans i18nKey={"funnel.tariffs.internet_and_mobile.footer"}>
          <Link target="_parent" to={resolvePersonalizedUrl()} />
        </Trans>
      </Typography>
    </Footer>
  );
};
