// vendor
import { useTranslation } from "react-i18next";
import { useCopyToClipboard } from "react-use";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ShareIcon from "@mui/icons-material/Share";
import Tooltip from "@mui/material/Tooltip";

// source
import { Spinner } from "components/Spinner";
import { Button } from "components/Button";

const ShareButton = ({ pageRef, catRef }) => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const [isUrlPageCopied, setIsUrlPageCopied] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    isUrlPageCopied &&
      setTimeout(() => {
        setIsUrlPageCopied(false);
      }, 3000);
  }, [isUrlPageCopied]);

  function handleOnClickSharePage() {
    copyToClipboard(
      `${window.location.origin}${window.location.pathname}#/faqs/${catRef}/${pageRef}`
    );
    state.error ? setIsUrlPageCopied(false) : setIsUrlPageCopied(true);
  }

  return (
    <Tooltip title={t("common.copied_url")} open={isUrlPageCopied} arrow>
      <Button
        variant="transparent"
        endIcon={<ShareIcon />}
        size="small"
        onClick={handleOnClickSharePage}
      >
        {t("common.share")}
      </Button>
    </Tooltip>
  );
};

export default function PageContent({
  content,
  isActive,
  isLoading,
  ...props
}) {
  if (!isActive) return null;
  if (isLoading)
    return (
      <Box mt={8} mb={4}>
        <Spinner />
      </Box>
    );
  return (
    <Box>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <ShareButton {...props} />
    </Box>
  );
}
