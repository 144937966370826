import { Box, Typography, useMediaQuery } from "@mui/material";
import { Button } from "components/Button";
import { getLoginUrl, getSignupUrl } from "lib/helpers/urls";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "hooks/useQueryParams";
import { Link } from "components/Link";

export const RoleSelector = ({
  lines,
  isFiberAssociated,
  isPromotionActive,
  coop_agreement_code,
  sx,
  buttonsLayout,
}) => {
  const { is_company } = useQueryParams();
  const isCompany = is_company === "true";
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const signupUrl = (
    role,
    coopAgreementCode = undefined,
    isPromotionActive = false
  ) =>
    getSignupUrl(
      lines,
      role,
      coopAgreementCode,
      true,
      isFiberAssociated,
      false,
      isPromotionActive,
      isCompany
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: isMobile ? "16px 16px 24px 16px" : "24px",
        ...sx,
      }}
    >
      <Typography variant={isMobile ? "body" : "h4"}>
        {t("funnel.tariffs.modal_contract.select_contract")}
      </Typography>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {!isPromotionActive && (
          <Link
            target="_parent"
            to={signupUrl("sponsored")}
            showUnderline={false}
          >
            <Button
              size={"normal"}
              variant="transparent"
              sx={{ textTransform: "none" }}
              fullWidth
            >
              {t("funnel.tariffs.modal_contract.sponsored")}
            </Button>
          </Link>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: buttonsLayout,
            gap: 1,
          }}
        >
          <Link
            target="_parent"
            to={getLoginUrl(
              lines,
              isFiberAssociated,
              isPromotionActive,
              isCompany
            )}
            showUnderline={false}
            style={{ width: "100%" }}
          >
            <Button
              size={"normal"}
              variant="secondary"
              endIcon={<ArrowForwardIcon />}
              sx={{ textTransform: "none" }}
              fullWidth
            >
              {t("funnel.tariffs.modal_contract.already_user")}
            </Button>
          </Link>
          <Link
            target="_parent"
            to={signupUrl(
              "coop_agreement",
              isPromotionActive ? coop_agreement_code : "SC",
              isPromotionActive
            )}
            showUnderline={false}
            style={{ width: "100%" }}
          >
            <Button
              size={"normal"}
              endIcon={<ArrowForwardIcon />}
              sx={{ textTransform: "none" }}
              fullWidth
            >
              {t("funnel.tariffs.modal_contract.new_user")}
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
