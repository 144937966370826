import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { theme } from "theme";
import { Button } from "components/Button";
import { HeaderCard } from "./HeaderCard";
import {
  MobileAssociatedFiber,
  ThreeMobilesSharingData,
  TwoMobilesSharingData,
} from "components/icons/Products";
import { formatBandwith, formatDataPlanSize } from "lib/helpers/format";
import { CheckListCard } from "components/CheckListCard";
import { ModalTariffMoreInfo } from "components/modals/TariffMoreInfo";
import { useState } from "react";
import { PackTariffTitle } from "../TariffsTitle";

export const PricingCard = ({
  buttonVariant,
  cardBackground,
  mobiles,
  price,
  fiber_bandwidth,
  fiber_description,
  mobile_data,
  category,
  onContract,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  function getMobileDesc() {
    return `${formatDataPlanSize(mobile_data)} ${
      category === "mobile_shared_data"
        ? t("common.shared")
        : t("funnel.tariffs.recommended.mobile", { count: mobiles })
    }`;
  }
  function getPackDesc() {
    return `${formatBandwith(fiber_bandwidth)} + ${t(
      "funnel.tariffs.recommended.pricing_card.title",
      {
        count: mobiles,
        data: formatDataPlanSize(mobile_data),
      }
    )}`;
  }
  return (
    <>
      <ModalTariffMoreInfo
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        pageRef={"pack_mobile"}
        title={
          <PackTariffTitle
            mobileData={mobile_data}
            mobilesInPack={mobiles}
            fiberBandwidth={fiber_bandwidth}
            addLandlineInfo={true}
            hasLandline={false}
          />
        }
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            position: "relative",
            top: "35px",
            left: isMobile ? "15%" : "21%",
          }}
        >
          {(() => {
            if (mobiles === 1) {
              return <MobileAssociatedFiber />;
            } else if (mobiles === 2) {
              return <TwoMobilesSharingData />;
            } else if (mobiles === 3) {
              return <ThreeMobilesSharingData />;
            }
          })()}
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 6,
            padding: isMobile ? "65px 32px 30px" : "65px 60px 30px",
            borderRadius: "16px",
            border: `3px solid ${theme.palette.grey[900]}`,
            bgcolor: cardBackground,
            width: isMobile ? "310px" : "360px",
            height: "630px",
          })}
        >
          <HeaderCard description={getPackDesc()} price={price} />
          <Divider sx={{ borderColor: "grey.200" }} flexItem />
          <CheckListCard
            literals={[
              fiber_description,
              getMobileDesc(),
              t(
                "funnel.tariffs.internet_and_mobile.contract_info.unlimited_calls"
              ),
            ]}
          />
          <Button variant={buttonVariant} fullWidth onClick={onContract}>
            {t("common.contract")}
          </Button>
          <Typography onClick={() => setIsModalOpen(true)} variant="link">
            {t("common.learn_more")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
