import { useTranslation } from "react-i18next";
import { TariffCards } from "./Join/components/tariffCards";

export const Companies = () => {
  const { i18n } = useTranslation();
  function resolveCompanyUrl(target) {
    const url = window.location.origin + window.location.pathname;
    const lang = i18n.language;
    switch (target) {
      case "mobile":
        return `${url}${lang === "ca" ? "tarifes-mobil" : "tarifas-movil"}`;
      case "internet_and_mobile":
        return `${url}${
          lang === "ca" ? "tarifes-internet-mobil" : "tarifas-internet-movil"
        }`;
      default:
        return `${url}${
          lang === "ca" ? "tarifes-internet" : "tarifas-internet"
        }`;
    }
  }
  return <TariffCards getTo={(to) => resolveCompanyUrl(to)} />;
};
