import React from "react";
import Dialog from "@mui/material/Dialog";
import { noop } from "lib/fn/noop";
import {
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Modal = ({
  isOpen,
  title,
  onClose = noop,
  showCloseButton = true,
  disableBackdropClick = false,
  disableEscapeClick = false,
  children,
  ...props
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isWPInjected = process.env.REACT_APP_FORM_BUILD;
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && disableBackdropClick) return;
    if (reason && reason === "escapeKeyDown" && disableEscapeClick) return;
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          boxShadow: 3,
          maxWidth: isMobile ? "100%" : "720px",
          maxHeight: isWPInjected && (isMobile ? "100%" : "calc(100% - 100px)"),
        },
      }}
      sx={{ top: isWPInjected && (isMobile ? "64px" : "120px") }}
      {...props}
    >
      <DialogTitle
        sx={(theme) => ({
          py: 1,
          px: "16px",
          background: theme.palette.grey[200],
        })}
      >
        <Typography variant="body_bold">{title}</Typography>
      </DialogTitle>
      {showCloseButton && (
        <IconButton
          sx={(theme) => ({
            p: 0,
            position: "absolute",
            right: 16,
            top: 12,
            color: theme.palette.grey[900],
          })}
          onClick={onClose}
          aria-label="close_modal"
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
};
