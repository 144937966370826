// vendor
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// source
import { useCategories, useFaqsPage } from "hooks/queries/useFaqs";
import useStyles from "./useStyles";
import { DialogHelp } from "components/dialogs/DialogHelp";
import Sidebar from "./Sidebar";
import { Spinner } from "components/Spinner";
import useScroll from "./useScroll";
import PageContent from "./PageContent";

import "../../faqs.css";
import { RequestError } from "components/RequestError";

export default function Faqs() {
  const { t } = useTranslation();
  const styles = useStyles();
  const location = useLocation();
  const history = useHistory();

  const main = useRef();
  const list = useRef();

  const catRef = useMemo(
    () => location.pathname.match(/(?<=faqs\/)[^\/]+/)?.[0],
    [location.pathname]
  );
  const setCatRef = (catRef) => history.push(`/faqs/${catRef}`);

  const pageRef = useMemo(
    () => location.pathname.match(/(?<=faqs\/\w+\/)\w+$/)?.[0],
    [location.pathname]
  );
  const setPageRef = (pageRef) => {
    if (pageRef) history.push(`/faqs/${catRef}/${pageRef}`);
    else history.push(`/faqs/${catRef}`);
  };

  const {
    isLoading: isLoadingCats,
    error: catLoadError,
    data: categories = [],
    refetch: refetchCats,
  } = useCategories({ parentRef: "faqs" });

  const pages = useMemo(
    () =>
      categories.reduce(
        (pages, cat) =>
          pages.concat(
            cat.pages.map((page) => ({ ...page, category_ref: cat.ref }))
          ),
        []
      ),
    [categories]
  );

  const catPages = useMemo(
    () => pages.filter((page) => page.category_ref === catRef) || [],
    [catRef, pages]
  );

  const searchInputRef = useRef();
  const [searchRef, setSearchRef] = useState(null);
  const [searchPages, setSearchPages] = useState();
  const onSearch = Array.isArray(searchPages);

  const currentPages = onSearch ? searchPages : catPages;
  const currentPageRef = onSearch ? searchRef : pageRef;

  const {
    isLoading: isLoadingPage,
    error: pageLoadError,
    data: { content: pageContent } = {},
    refetch: refetchPage,
  } = useFaqsPage({ pageRef: currentPageRef, enabled: !!currentPageRef });

  useEffect(() => {
    if (isLoadingCats) return;
    if (catRef || pageRef) return;
    // Set default category on empty url states
    history.push("/faqs/" + categories[0].ref);
  }, [isLoadingCats]);

  // useEffect(() => {
  //   if (!catPages.length || onSearch) return;
  //   // set first page as default on category changes
  //   const pref = !!catPages.find(({ ref }) => ref === currentPageRef);
  //   if (!pref) setPageRef(catPages[0].ref);
  // }, [catPages, onSearch]);

  useScroll({
    main,
    list,
    catRef,
    pageRef,
    isLoading: isLoadingCats,
  });

  const selectPage = (pageRef) => {
    if (pageRef === currentPageRef) {
      pageRef = null;
    }
    if (onSearch) setSearchRef(pageRef);
    else setPageRef(pageRef);
  };

  const title = onSearch
    ? t("faqs.search.title", { pattern: "dades" }) +
      `: “${searchInputRef.current?.value}”`
    : categories.find(({ ref }) => ref === catRef)?.name;

  if (catLoadError || pageLoadError)
    return (
      <RequestError
        onClick={() => (catLoadError ? refetchCats : refetchPage)}
      />
    );
  if (isLoadingCats) return <Spinner />;

  return (
    <div className={styles.screen}>
      <Sidebar
        catRef={catRef}
        setCatRef={setCatRef}
        categories={categories}
        onSearch={onSearch}
        searchPages={searchPages}
        setSearchPages={setSearchPages}
        searchInputRef={searchInputRef}
      />
      <main className={styles.content} ref={main}>
        {(onSearch && !currentPages.length && (
          <h2 className={styles.notFound}>
            {t("faqs.page.not-found", {
              searched: searchInputRef.current?.value,
            })}
          </h2>
        )) || (
          <>
            <Typography variant="h3">{title}</Typography>
            <Box mt={4}>
              <ul ref={list} className={styles.pages + " faqs-content"}>
                {currentPages.map((page) => (
                  <li
                    id={"page_" + page.ref}
                    key={page.ref}
                    className={[styles.page, "page"].join(" ")}
                    data-cat={page.category_ref}
                  >
                    <h3
                      onClick={() => selectPage(page.ref)}
                      className={[
                        styles.pageTitle,
                        page.ref === currentPageRef ? "current" : "",
                      ].join(" ")}
                    >
                      {page.name}
                    </h3>
                    <PageContent
                      isActive={page.ref === currentPageRef}
                      isLoading={isLoadingPage}
                      content={pageContent}
                      pageRef={page.ref}
                      catRef={onSearch ? page.breadcrumb[1] : page.category_ref}
                    />
                  </li>
                ))}
              </ul>
            </Box>
          </>
        )}
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          })}
        >
          <DialogHelp />
        </Box>
      </main>
    </div>
  );
}
